import React from "react"
import { Container } from "reactstrap"

const Covid = () => (
  <div className="covid">
    <Container>
      <div class="image-box">
        <div>
          <strong>Happy Hour : During Any Live Game!! <br/>(Monday-Friday 5:00pm to Close!!)</strong>
          <br />
          <br />
          Bottled Beer: <br/>
          All Bottle & Draft Beer: $4.50<br/>
          <br />
          Wine: 
          Glass     $4.00          <br />
        
        </div>
      </div>
    </Container>
  </div>
)

export default Covid
