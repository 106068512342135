import React from "react"
import { Container } from "reactstrap"
import { useStaticQuery, graphql } from "gatsby"

import SpecialImage from "./special-image"

const LunchSpecials = () => {
  //lunch special data
  const data = useStaticQuery(graphql`
    {
      internalResData(name: { eq: "Lunch Specials - Monday - Friday" }) {
        name
        description
        id
      }
    }
  `)

  return (
    <div className="l-specials">
      <Container>
        <div className="row">
          <div className="col-lg-6 s-img">
            <SpecialImage />
          </div>
          <div className="col-lg-6">
            <div class="d-flex h-100 align-items-center ">
              <div>
                <span class="btn btn-danger s-time">
                  Only 11:00 AM - 4:00PM
                </span>
                {
                  data?.internalResData?.name && (
                    <h6 className="">{data.internalResData.name}</h6>
                  )
                }
              
              {
                  data?.internalResData?.description && (
                    <div className="s-list">
                    <p className="item-description">{data.internalResData.description}</p>
                  </div>
                  )
              }      
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}
export default LunchSpecials
