import React from "react"
import { Link } from "gatsby"

const Button = props => {
  return (
    <Link className="btn my-button" to={props.url}>
      <span className="myover">{props.name}</span>
    </Link>
  )
}

export default Button
