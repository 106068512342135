import React from "react"
import { Container } from "reactstrap"
import { useStaticQuery, graphql } from "gatsby"

const Specials = () => {
  //special data
  const data = useStaticQuery(graphql`
    {
      internalResData(name: { eq: "Specials" }) {
        name
        items {
          name
          price
          description
        }
        description
        id
      }
    }
  `)

  const { name, description, items } = data.internalResData
  return (
    <div className="h-specials">
      <Container>
        <div className="d-flex justify-content-center">
          <div className="hm-specials">
            <h6 className="text-white text-center">{name}</h6>
            <p className="text-white h-p text-center">
              Check our daily special here
            </p>
            <div className="s-list">
              <p className="text-white text-center item-des">{description}</p>
              {items.map(item => (
                <div key={item.name} className="special-item">
                  <div className="inline-block mr-4">
                    <span className="item-name">{item.name}</span>
                    <span className="float-right item-price">{`$${item.price}`}</span>
                  </div>
                  <p className="item-des">{item.description}</p>
                </div>
              ))}
              <div className="d-flex justify-content-center">
                <span className="sp-order ">
                  For online ordering please click Order Online button.
                </span>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}
export default Specials
