import React from "react"
import Layout from "../components/layout/layout"
import Covid from "../components/home/covid"
import Header from "../components/home/header"
import SEO from "../components/seo"
import Menu from "../components/home/menu"
import Specials from "../components/home/specials"
import LunchSpecials from "../components/home/lunch-specials"
import Logo from "../images/icons/ribslogo.png"
import { graphql } from "gatsby"

const IndexPage = ({ data }) => {
  //site meta data
  const {
    name,
    siteUrl,
    tel,
    menu,
    priceRange,
    servesCuisine,
    acceptsReservations,
    streetAddress,
    addressLocality,
    addressRegion,
    postalCode,
    addressCountry,
    latitude,
    longitude,
    facebook,
    twitter,
    instagram,
  } = data.site.siteMetadata

  //for schema
  const schema = {
    "@context": "https://schema.org",
    "@type": "Restaurant",
    name,
    image: siteUrl + Logo,
    "@id": "",
    url: siteUrl,
    telephone: tel,
    priceRange,
    menu,
    servesCuisine,
    acceptsReservations,
    address: {
      "@type": "PostalAddress",
      streetAddress,
      addressLocality,
      addressRegion,
      postalCode,
      addressCountry,
    },
    geo: {
      "@type": "GeoCoordinates",
      latitude,
      longitude,
    },
    sameAs: [facebook, twitter, instagram],
  }
  return (
    <Layout>
      <SEO
        schemaMarkup={schema}
        title="Ribs USA - Food delivery - Burbank - Order online"
        keywords="ribs usa, ribs usa bbq, ribs, usa, bbq, best bbq near me, baby back ribs, beef ribs near me, best beef ribs near me, bbq chicken near me, brisket near me, pulled pork sandwich, brisket sandwich,  baby back ribs near me"
      />
      <Header />
      <main>
        <Covid />
        <Specials />
        <LunchSpecials />
        <Menu />
      </main>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  {
    site {
      siteMetadata {
        name
        siteUrl
        tel
        image
        menu
        priceRange
        servesCuisine
        acceptsReservations
        streetAddress
        addressLocality
        addressRegion
        postalCode
        addressCountry
        latitude
        longitude
        facebook
        twitter
        instagram
      }
    }
  }
`
