import React from "react"
import { CardColumns, Container } from "reactstrap"
import Card1 from "./menu/card1"
import Card2 from "./menu/card2"
import Card3 from "./menu/card3"

const Menu = () => {
  return (
    <section id="image-card">
      <Container>
        <CardColumns>
          <Card1 />
          <Card2 />
          <Card3 />
        </CardColumns>
      </Container>
    </section>
  )
}

export default Menu
