import React, { useEffect, Fragment } from "react"
import Status from "./status"
import Button from "../button"
import Slider1 from "./slider/slider1"
import Slider2 from "./slider/slider2"

const Header = () => {
  useEffect(() => {
    const slider = document.querySelector("#slider")

    if (slider) {
      let sliderSlides = slider.querySelectorAll(".slider-item")
      let sliderSwitcher = slider.querySelector(".switch")
      const sliderCount = sliderSlides.length
      let imageSlideShow
      let i = 0
      while (sliderSwitcher.querySelectorAll("i").length !== sliderCount) {
        let i = document.createElement("i")
        sliderSwitcher.appendChild(i)
      }
      sliderSwitcher = sliderSwitcher.querySelectorAll("i")
      const forwardSliderImage = i => {
        if (i === 0) {
          sliderSlides[sliderCount - 1].classList.remove("show")
          sliderSlides[sliderCount - 1].classList.add("close")
          sliderSwitcher[sliderCount - 1].classList.remove("active")
        } else {
          sliderSlides[i - 1].classList.remove("show")
          sliderSlides[i - 1].classList.add("close")
          sliderSwitcher[i - 1].classList.remove("active")
        }
        if (i === sliderCount - 1) {
          sliderSlides[0].classList.remove("close")
        } else {
          sliderSlides[i + 1].classList.remove("close")
        }
        sliderSwitcher[i].classList.add("active")
        sliderSlides[i].classList.add("show")
      }

      const backwardSliderImage = i => {
        if (i === 0) {
          sliderSlides[sliderCount - 1].classList.remove("show")
          sliderSlides[sliderCount - 1].classList.add("close")
          sliderSwitcher[sliderCount - 1].classList.remove("active")
        } else {
          sliderSlides[i - 1].classList.remove("show")
          sliderSlides[i - 1].classList.add("close")
          sliderSwitcher[i - 1].classList.remove("active")
        }
        if (i === sliderCount - 1) {
          sliderSlides[0].classList.remove("close")
        } else {
          sliderSlides[i + 1].classList.remove("close")
        }

        if (i < sliderCount - 1) {
          sliderSlides[i + 1].classList.remove("show")
          sliderSwitcher[i + 1].classList.remove("active")
        } else {
          sliderSlides[0].classList.remove("show")
          sliderSwitcher[0].classList.remove("active")
        }
        sliderSwitcher[i].classList.add("active")
        sliderSlides[i].classList.add("show")
      }

      function startSlideShow() {
        imageSlideShow = setInterval(() => {
          nextSliderImage()
        }, 5000)
      }

      function nextSliderImage() {
        i++
        if (i === sliderCount) {
          i = 0
        }
        forwardSliderImage(i)
        clearInterval(imageSlideShow)
        startSlideShow()
      }

      function previousSliderImage() {
        i--
        if (i === -1) {
          i = sliderCount - 1
        }
        backwardSliderImage(i)
        clearInterval(imageSlideShow)
        startSlideShow()
      }

      // initializing slider buttons
      const prevBtn = slider.querySelector(".prev")
      const nextBtn = slider.querySelector(".next")

      // adding event listener to next and prev buttons
      nextBtn.addEventListener("click", nextSliderImage)
      prevBtn.addEventListener("click", previousSliderImage)

      // showing 1st slide
      forwardSliderImage(i)
      startSlideShow()
    }
  })

  return (
    <Fragment>
      <div className="slider-content d-flex justify-content-center">
        <Status />
      </div>

      <div className="slider" id="slider">
        <div className="slider-item">
          <div className="slider-content d-flex justify-content-center">
            <div className="header-content text-center">
              <h1>WELCOME TO RIBS USA</h1>
              <p>
                Ribs USA. is a moderately priced Burbank family restaurant featuring
                high quality barbecued foods.
              </p>
              <Button url="/about-us" name="About Us" />
            </div>
          </div>
          <Slider1 />
        </div>

        <div className="slider-item">
          <div className="slider-content d-flex justify-content-center">
            <div className="header-content text-center">
              <h1>Check Out Our Full Menu</h1>
              <p>
                Ribs USA is a moderately priced family restaurant featuring
                high quality barbecued foods.
              </p>
              <Button url="/full-menu" name="Full Menu" />
            </div>
          </div>
          <Slider2 />
        </div>

        <button className="prev">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
          </svg>
        </button>
        <button className="next">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
          </svg>
        </button>

        <span className="switch"></span>
      </div>
    </Fragment>
  )
}

export default Header
