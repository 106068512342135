import React from "react"
import Img from "gatsby-image"
import { Card } from "reactstrap"
import { Link, useStaticQuery, graphql } from "gatsby"

const Card1 = () => {
  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "m1.jpg" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Card className="menu-image" key={data.file.id} inverse>
      <div className="over-menu">
        <h3>
          <Link className="btn my-button" to="/full-menu">
            <span className="myover">Full Menu</span>
          </Link>
        </h3>
      </div>
      <Img
        className="card-img-top"
        fluid={data.file.childImageSharp.fluid}
        objectFit="cover"
      />
    </Card>
  )
}

export default Card1
