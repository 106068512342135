import React from "react"
// import Img from "gatsby-image"
// import { useStaticQuery } from "gatsby"
import SliderImage from "../../../images/home/s2.jpg"

const Slider2 = () => {
  //   const data = useStaticQuery(graphql`
  //     {
  //       file(relativePath: { eq: "slider.jpg" }) {
  //         id
  //         childImageSharp {
  //           fluid {
  //             ...GatsbyImageSharpFluid
  //           }
  //         }
  //       }
  //     }
  //   `)
  return <img src={SliderImage} alt="Slider2" />
  //   return <Img fluid={data.file.childImageSharp.fluid} />
}

export default Slider2
