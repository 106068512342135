import React from "react"
import { Container } from "reactstrap"

const Status = () => (
  <div className="status">
    <div className="status-bar">
      <Container>
        <p className="text-center">
          We are now open for Take-out, Delivery, and Catering. Please give us a
          call for any questions <br />
          <a href="tel:818-841-8872">818-841-8872</a>
          <br/>
        </p>
      </Container>
    </div>
  </div>
)

export default Status
